import React from "react";
import ProjectContainer from "./proj-container/proj-container";

export const ProjectGallery = () => {
  return(
    <div>
      <ProjectContainer/>
    </div>
  )
}

export default ProjectGallery;
